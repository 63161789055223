import EntryActionTypes from '../constants/EntryActionTypes';

export const logout = () => ({
  type: EntryActionTypes.LOGOUT,
  payload: {},
});

export const handleResize = () => ({
  type: EntryActionTypes.HANDLE_RESIZE,
  payload: {},
});
