import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { embeddedLocales, languages } from './locales';
import deCore from './locales/de/core.js';

i18n.use(initReactI18next).init({
  resources: embeddedLocales,
  fallbackLng: 'de',
  supportedLngs: languages,
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
});

const loadCoreLocale = async (language = i18n.resolvedLanguage) => {
  /* @vite-ignore */

  Object.keys(deCore).forEach((namespace) => {
    i18n.addResourceBundle(language, namespace, deCore[namespace], true, true);
  });
};

const augmentedI18n = i18n as typeof i18n & {
  loadCoreLocale: typeof loadCoreLocale;
};
augmentedI18n.loadCoreLocale = loadCoreLocale;
export default augmentedI18n;
