import { connect } from 'react-redux';
import Fixed from '../components/Fixed';
import * as selectors from '../selectors';

const mapStateToProps = (state) => {
  const { projectId, isBoardAccess, isBoardSettings, isBoardDuplicate, isImport, cardId } =
    selectors.selectPath(state);
  const currentBoard = selectors.selectCurrentBoard(state);
  const { isMobile } = state.root;
  const currentModal = isBoardAccess || isBoardSettings || isBoardDuplicate || isImport || cardId;
  const show = !(isMobile && currentModal);
  return {
    projectId,
    board: currentBoard,
    show,
  };
};

export default connect(mapStateToProps)(Fixed);
