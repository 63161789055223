/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flags from '../../constants/Flags';
import * as Keys from '../../constants/Keys';
import usePopup from '../../lib/popup/use-popup.js';
import CalendarButton from '../CalendarButton/CalendarButton.js';
import styles from '../CardModal/CardModal.module.scss';
import NameField from '../CardModal/NameField';
import CardModalContentDescription from '../CardModalContentDescription/CardModalContentDescription';
import ColorChooser from '../ColorChooser/ColorChooser';
import DatePickStep from '../DatePickStep/DatePickStep.js';
import DuplicateButton from '../DuplicateButton/DuplicateButton.js';
import FormEmbedOrMediaUpload from '../FormEmbedOrMediaUpload/FormEmbedOrMediaUpload';
import FormError from '../FormError/FormError';

function CardModalContent({
  name,
  description,
  canEdit,
  error,
  handleNameUpdate,
  handleDescriptionUpdate,
  embedUrl,
  setEmbedUrl,
  mediaUrl,
  onMediaUpdate,
  onMediaDelete: onImageDelete,
  isMediaUpdating,
  ogImage,
  ogTitle,
  ogDescription,
  isEmpty,
  backgroundColor,
  setBackgroundColor,
  backgroundColors,
  showCalendar,
  showDuplicate,
  dateTime,
  setDateTime,
  openCardDuplicateModal,
  mediaPreviewUrl,
  mediaLinkUrl,
  descriptionFocusViaTab,
  descriptionEditOpen,
  openDescriptionEdit,
  closeDescriptionEdit,
  customImageUpload,
  handlePreviewFileSelect,
  handlePreviewFileDelete,
}: {
  error: any;
  name: string;
  description: string;
  canEdit: boolean;
  handleNameUpdate: (value: string) => void;
  handleDescriptionUpdate: (value: string) => void;
  embedUrl: string;
  setEmbedUrl: any;
  mediaUrl: string;
  onMediaUpdate: ({ file }: { file: File | null }) => void;
  handlePreviewFileSelect: ({ file }: { file: File | null }) => void;
  handlePreviewFileDelete: () => void;
  onMediaDelete: () => void;
  isMediaUpdating: boolean;
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
  isEmpty?: boolean;
  backgroundColor: string;
  setBackgroundColor: (color: string) => void;
  backgroundColors: readonly string[];
  showCalendar: boolean;
  showDuplicate: boolean;
  dateTime: string;
  mediaPreviewUrl?: string;
  setDateTime: (dateTime: string) => void;
  openCardDuplicateModal: () => void;
  mediaLinkUrl?: string;
  descriptionFocusViaTab?: boolean;
  descriptionEditOpen: boolean;
  openDescriptionEdit: () => void;
  closeDescriptionEdit: () => void;
  customImageUpload: boolean;
}) {
  const [t] = useTranslation();
  const DatePickPopup = usePopup(DatePickStep, undefined, 'w-[340px]');

  const [forceOpen, setForceOpen] = useState(0);
  const handleKeyDown = useCallback(
    (event: any) => {
      const { key } = event;
      if (key === Keys.Tab && descriptionFocusViaTab) {
        event.preventDefault();
        setForceOpen((value) => value + 1);
      }
    },
    [descriptionFocusViaTab],
  );
  const hasOther = Boolean(description) || Boolean(embedUrl) || Boolean(mediaUrl);
  return (
    <div
      className="bg-white text-blue100 p-8 pr-6 pb-6"
      style={{
        backgroundColor,
      }}
    >
      <header className="flex items-center gap-2 mb-6">
        <img src="/assets/images/icon-add-document-green.svg" alt="" />
        <span className="text-2xl text-blue100 font-bold">{t('common.cardEdit')}</span>
      </header>

      <div className="absolute md:right-5 md:top-5 right-12 top-2 flex gap-4">
        {showDuplicate && (
          <div>
            <DuplicateButton onClick={openCardDuplicateModal} />
          </div>
        )}
        {showCalendar && (
          <div>
            <DatePickPopup dateTime={dateTime} handleSave={setDateTime}>
              <CalendarButton />
            </DatePickPopup>
          </div>
        )}
      </div>

      <div className={styles.headerWrapper}>
        <div className={styles.headerTitleWrapper}>
          {canEdit ? (
            <NameField
              // @ts-ignore
              defaultValue={name}
              onUpdate={handleNameUpdate}
              autofocus={isEmpty}
              onKeyDown={handleKeyDown}
              canBeEmpty={hasOther}
            />
          ) : (
            <div className={styles.headerTitle}>{name}</div>
          )}
        </div>
      </div>
      <div className="mb-6">
        <FormEmbedOrMediaUpload
          embedUrl={embedUrl}
          setUrl={setEmbedUrl}
          mediaUrl={mediaUrl}
          onMediaUpdate={onMediaUpdate}
          onImageDelete={onImageDelete}
          isMediaUpdating={isMediaUpdating}
          canEdit={canEdit}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          mediaPreviewUrl={mediaPreviewUrl}
          mediaLinkUrl={mediaLinkUrl}
          customImageUpload={customImageUpload}
          handlePreviewFileSelect={handlePreviewFileSelect}
          handlePreviewFileDelete={handlePreviewFileDelete}
        />
      </div>

      {Flags.CARD_BACKGROUND && (
        <ColorChooser
          colors={backgroundColors}
          currentColor={backgroundColor}
          setCurrentColor={setBackgroundColor}
          label={t('common.chooseCardColor')}
        />
      )}
      {(description || canEdit) && (
        <CardModalContentDescription
          description={description}
          canEdit={canEdit}
          handleDescriptionUpdate={handleDescriptionUpdate}
          initialForceOpen={forceOpen}
          descriptionEditOpen={descriptionEditOpen}
          openDescriptionEdit={openDescriptionEdit}
          closeDescriptionEdit={closeDescriptionEdit}
        />
      )}

      <FormError error={error} />
    </div>
  );
}

export default React.memo(CardModalContent);
