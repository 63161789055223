/* eslint-disable no-restricted-syntax */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Projects from '../components/Projects';
import Flags from '../constants/Flags.js';
import * as entryActions from '../entry-actions';
import * as selectors from '../selectors';
import type { SortOrder } from '../types/SortOrder';

const sortOrders: readonly SortOrder[] = ['created_desc', 'updated_desc', 'most_favorited_desc'];

const mapStateToProps = (state) => {
  const {
    ui: {
      projectFilterForm: { professionIds, sortOrder, page },
      communityProjects: { canLoadMore, error: loadMoreError },
    },
  } = state;
  const user = selectors.selectCurrentUser(state);
  const projects = selectors.selectProjectsForCurrentUser(state);
  const communityProjects = selectors.selectCommunityProjects(state);
  const professions = selectors.selectProfessions(state);
  const favoriteItems = selectors.selectFavoriteProjects(state);
  const items = projects;
  const showWelcome = !items;
  const showMyBoards = Boolean(items);
  const showProjectsSort = Flags.PROJECTS_SORT;
  return {
    favoriteItems,
    items: projects,
    communityItems: communityProjects,
    canAdd: !!user,
    professions,
    professionIds,
    canLoadMore,
    loadMoreError: loadMoreError !== null,
    showMyBoards,
    showWelcome,
    sortOrders,
    showProjectsSort,
    sortOrder,
    communityPage: page,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAdd: entryActions.openProjectAddModal,
      updateFilter: entryActions.projectFilterUpdate,
      addBookmark: entryActions.addBookmark,
      removeBookmark: entryActions.removeBookmark,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
