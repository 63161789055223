import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AboutModal from '../components/AboutModal/AboutModal';
import * as entryActions from '../entry-actions/index';

const mapStateToProps = () => {
  return () => {
    return {};
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AboutModal);
