import classNames from 'classnames';
import Embed from '../Embed/Embed';
import MediaPreviewImage from '../MediaPreviewImage/MediaPreviewImage.js';

function MediaPreviewEmbed({
  url,
  getHeightOfIframe,
  testId = 'MediaPreviewEmbed',
  makeEmbedTakeFullHeight = false,
  defaultPreviewDeleted,
  mediaPreviewUrl,
}: {
  url: string;
  getHeightOfIframe?: (mediaPreviewType: number) => number;
  testId?: string;
  makeEmbedTakeFullHeight?: boolean;
  defaultPreviewDeleted?: boolean;
  mediaPreviewUrl?: string;
}) {
  const heightOfIframe = getHeightOfIframe ? getHeightOfIframe(0) : undefined;
  return (
    <div
      className={classNames(
        'flex h-full max-h-full contain-content',
        testId === 'MediaPreviewYoutubeShort' ? 'w-auto' : 'w-full',
      )}
      data-testid={testId}
    >
      {!defaultPreviewDeleted && !mediaPreviewUrl && (
        <Embed
          url={url}
          heightOfIframe={heightOfIframe}
          makeEmbedFullHeight={makeEmbedTakeFullHeight}
        />
      )}
      {mediaPreviewUrl && <MediaPreviewImage url={mediaPreviewUrl} mediaLinkUrl={url} />}
    </div>
  );
}

export default MediaPreviewEmbed;
