import useCustomDimensions from '../../hooks/useCustomDimensions';
import MediaPreviewEmbed from '../MediaPreviewEmbed/MediaPreviewEmbed';

function MediaPreviewYoutube({
  url,
  getHeightOfIframe,
}: {
  url: string;
  getHeightOfIframe?: (previewType: number) => number;
}) {
  const { ref, dimensions } = useCustomDimensions({
    aspectRatio: 16 / 9,
    baseWidth: 560,
    baseHeight: 360,
  });

  return (
    <div
      ref={ref}
      style={{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }}
      className="w-full max-h-full"
    >
      <MediaPreviewEmbed
        url={url}
        getHeightOfIframe={getHeightOfIframe}
        testId="MediaPreviewYoutube"
        makeEmbedTakeFullHeight
      />
    </div>
  );
}

export default MediaPreviewYoutube;
