/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import Flags from '../../constants/Flags.js';
import ExternalLink from '../ExternalLink/ExternalLink.js';

const getShowImage = (
  mediaPreviewUrl: string,
  ogImage: string,
  defaultPreviewDeleted: boolean,
  customImageUpload: boolean,
) => {
  if (customImageUpload && mediaPreviewUrl) {
    return true;
  }
  if (defaultPreviewDeleted) {
    return false;
  }
  return mediaPreviewUrl || (ogImage && !defaultPreviewDeleted);
};

const getImageSrc = (ogImage: string, customImageUpload: boolean, mediaPreviewUrl: string) => {
  if (customImageUpload && mediaPreviewUrl) {
    return mediaPreviewUrl;
  }
  return ogImage;
};

function OpenGraphInfo({
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  isLarge,
  defaultPreviewDeleted,
  mediaUrl,
  mediaPreviewUrl,
  customImageUpload,
}: {
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
  isLarge?: boolean;
  defaultPreviewDeleted: boolean;
  mediaUrl?: string;
  mediaPreviewUrl?: string;
  customImageUpload: boolean;
}) {
  const showImage = getShowImage(
    mediaPreviewUrl,
    ogImage,
    defaultPreviewDeleted,
    customImageUpload,
  );
  const imageSrc = getImageSrc(ogImage, customImageUpload, mediaPreviewUrl);
  return (
    <ExternalLink
      url={ogUrl}
      className="flex flex-col h-full max-w-full w-full max-h-full contain-content"
    >
      {showImage && (
        <div
          className={classNames(
            'flex h-full flex-grow contain-content',
            isLarge ? 'min-w-[300px]' : 'min-w-[100px] min-h-[100px]',
          )}
        >
          <img src={imageSrc} alt="" className="mb-2 object-contain w-full" />
        </div>
      )}
      <p className="text-sm">{ogTitle}</p>
      {Flags.OPEN_GRAPH_DESCRIPTION && ogDescription && (
        <p className="text-blue100">{ogDescription}</p>
      )}
    </ExternalLink>
  );
}

export default OpenGraphInfo;
