import { connect } from 'react-redux';
import Static from '../components/Static';
import * as selectors from '../selectors';

const mapStateToProps = (state) => {
  const {
    cardId,
    projectId,
    isSearch,
    isDataProtection,
    isImprint,
    isImport,
    isNotFound,
    isLicenses,
    isTrash,
  } = selectors.selectPath(state);
  const requiresProject = !(isDataProtection || isImprint || isImport);
  const currentProject = requiresProject ? selectors.selectCurrentProject(state) : undefined;
  const currentBoard = requiresProject ? selectors.selectCurrentBoard(state) : undefined;
  const theme = selectors.selectProjectTheme(currentProject);
  const boardScrollFull = selectors.selectBoardScrollFull(state);

  return {
    projectId,
    cardId,
    board: currentBoard,
    project: currentProject,
    isSearch,
    isDataProtection,
    isImprint,
    isImport,
    isNotFound,
    isLicenses,
    theme,
    boardScrollFull,
    isTrash,
  };
};

export default connect(mapStateToProps)(Static);
