import EntryActionTypes from '../constants/EntryActionTypes';

export const handleProfessionAddToProject = (id, projectId) => ({
  type: EntryActionTypes.PROFESSION_TO_PROJECT_ADD,
  payload: {
    id,
    projectId,
  },
});

export const handleProfessionRemoveFromProject = (projectProfession) => ({
  type: EntryActionTypes.PROFESSION_FROM_PROJECT_REMOVE,
  payload: {
    projectProfession,
  },
});
