import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import HeaderSearch from '../components/HeaderSearch/HeaderSearch.js';
import Paths from '../constants/Paths';
import * as entryActions from '../entry-actions';
import { push } from '../lib/redux-router';

const mapStateToProps = (state) => {
  const {
    ui: {
      searchForm: { searchValue },
    },
  } = state;
  return {
    value: searchValue,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      setValue: entryActions.searchValueUpdate,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  handleEnter: () => dispatchProps.push(`${Paths.ROOT}?search=${stateProps.value}`),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HeaderSearch);
