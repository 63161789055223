import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as MediaPreviewType from '../../constants/MediaPreviewType';

const shouldRightAlignButton = (previewType: number) => {
  switch (previewType) {
    case MediaPreviewType.Pdf:
    case MediaPreviewType.Link:
    case MediaPreviewType.EmbedGoogleMaps:
    case MediaPreviewType.EmbedInstagram:
    case MediaPreviewType.EmbedPdf:
    case MediaPreviewType.EmbedSoundcloud:
    case MediaPreviewType.EmbedTwitter:
    case MediaPreviewType.EmbedVimeo:
    case MediaPreviewType.EmbedYoutube:
      return true;
    default:
      return false;
  }
};

function MediaRemovePreviewImageButton({
  handleDelete,
  previewType,
}: {
  handleDelete: () => void;
  previewType: number;
}) {
  const [t] = useTranslation();
  const alignRight = shouldRightAlignButton(previewType);
  return (
    <div
      className={classNames(
        'absolute bg-transparent flex items-center justify-center',
        alignRight ? 'right-3 top-0 bottom-0' : 'inset-0',
      )}
    >
      <div className="p-8 bg-blue70 flex flex-col items-center gap-3">
        <button
          className="flex justify-center items-center p-3 border-none rounded-sm bg-blue70 text-white100 hover:bg-blue30 text-white100"
          aria-label={t('common.deleteFile')}
          type="button"
          title={t('common.deleteFile')}
          onClick={handleDelete}
        >
          <img src="/assets/images/icon-trash-white.svg" alt="" />
        </button>
        <p className="text-white">Vorschaubild entfernen</p>
      </div>
    </div>
  );
}

export default MediaRemovePreviewImageButton;
