import EntryActionTypes from '../constants/EntryActionTypes';

export const authenticate = (data) => ({
  type: EntryActionTypes.AUTHENTICATE,
  payload: {
    data,
  },
});

export const authenticateUsingOidc = () => ({
  type: EntryActionTypes.USING_OIDC_AUTHENTICATE,
  payload: {},
});

export const registerUsingOidc = () => ({
  type: EntryActionTypes.USING_OIDC_REGISTER,
  payload: {},
});

export const clearAuthenticateError = () => ({
  type: EntryActionTypes.AUTHENTICATE_ERROR_CLEAR,
  payload: {},
});
