import { useEffect, useRef, useState } from 'react';

function useCustomDimensions({
  aspectRatio,
  baseWidth,
  baseHeight,
}: {
  aspectRatio: number;
  baseWidth: number;
  baseHeight: number;
}) {
  const [dimensions, setDimensions] = useState({ width: baseWidth, height: baseHeight });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        const { height: parentHeight, width: parentWidth } =
          ref.current.parentElement.getBoundingClientRect();

        const potentialHeight = parentWidth / aspectRatio;
        const potentialWidth = parentHeight * aspectRatio;

        if (potentialWidth > parentWidth) {
          setDimensions({ width: parentWidth, height: potentialHeight });
        } else if (potentialHeight > parentHeight) {
          setDimensions({ width: potentialWidth, height: parentHeight });
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (ref.current) {
      resizeObserver.observe(ref.current.parentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [aspectRatio]);

  return { ref, dimensions };
}

export default useCustomDimensions;
