import { call, put, select, take } from 'redux-saga/effects';
import { authenticateUsingOidcCallback } from './login.js';
import ActionTypes from '../../../constants/ActionTypes';
import Paths from '../../../constants/Paths';
import { push } from '../../../lib/redux-router';
import * as selectors from '../../../selectors';

export function* goToUrl(path: string) {
  yield put(push(path));
}

export function* goToRoot() {
  yield put(push(Paths.ROOT));
}

export function* handleLocationChange() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  if (!pathsMatch) {
    return;
  }

  switch (pathsMatch.pattern.path) {
    case Paths.OIDC_CALLBACK: {
      const isInitializing = yield select(selectors.selectIsInitializing);

      if (isInitializing) {
        yield take(ActionTypes.LOGIN_INITIALIZE);
      }

      yield call(authenticateUsingOidcCallback);

      break;
    }
    default:
  }
}

export default {
  goToUrl,
  goToRoot,
  handleLocationChange,
};
