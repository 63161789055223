import katex from 'katex';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useCallback, useMemo } from 'react';
import { tryParse } from '../QuillWrapper/QuillWrapper';
import './ContentPreviewQuill.css';
import 'katex/dist/katex.min.css';

function ContentPreviewQuill({ value }: { value: string }) {
  const html = useMemo(() => {
    const parsed = tryParse(value);
    const converter = new QuillDeltaToHtmlConverter(parsed.ops || parsed, {
      urlSanitizer: (link) => link,
    });

    return converter.convert();
  }, [value]);

  const refCallback = useCallback((contentPreviewDiv: HTMLDivElement) => {
    if (contentPreviewDiv) {
      const elements = contentPreviewDiv.querySelectorAll<HTMLElement>('.ql-formula');
      elements.forEach((element) => {
        element.classList.remove('ql-formula'); // to disable double rendering
        katex.render(element.textContent || '', element, {
          throwOnError: false,
        });
      });
    }
  }, []);

  return (
    <div
      ref={refCallback}
      className="content-preview"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}

export default ContentPreviewQuill;
