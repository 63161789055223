import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Trash from '../components/Trash/Trash';
import * as entryActions from '../entry-actions/index';

const mapStateToProps = (state) => {
  const {
    ui: {
      projectTrashPage: { error, items, itemError },
    },
  } = state;
  return {
    projects: items,
    error,
    itemError,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      restoreProjectFromTrash: entryActions.restoreProjectFromTrash,
      deleteProjectFromTrash: entryActions.deleteProject,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Trash);
