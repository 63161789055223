import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType.js';
import { Input } from '../../lib/custom-ui';
import Button from '../Button/Button.js';
import EmbedPreview from '../EmbedPreview/EmbedPreview';

function FormEmbedUpload({
  url,
  setUrl,
  canEdit,
  ogImage,
  ogTitle,
  ogDescription,
  setDefaultPreviewDeleted,
  defaultPreviewDeleted,
  mediaLinkUrl,
  mediaUrl,
  mediaPreviewUrl,
  onMediaUpdate,
  onImageDelete,
  isMediaUpdating,
  customImageUpload,
}: {
  url: string;
  setUrl: (url: string) => void;
  canEdit: boolean;
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
  setDefaultPreviewDeleted: (value: boolean) => void;
  defaultPreviewDeleted: boolean;
  mediaUrl: string;
  onMediaUpdate: ({ file }: { file: File | null }) => void;
  onImageDelete: () => void;
  isMediaUpdating: boolean;
  mediaPreviewUrl?: string;
  mediaLinkUrl?: string;
  customImageUpload: boolean;
}) {
  const handleSubmit = (value) => {
    const trimmedUrl = value.trim();
    setUrl(trimmedUrl);
  };

  const handleBlur = (event: any) => {
    handleSubmit(event.target.value);
  };

  const handleEnter = (event: any) => {
    handleSubmit(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleEnter(event);
    }
  };

  const [t] = useTranslation();
  const [currentValue, setCurrentValue] = useState(url);
  const handleChange = (event: any) => {
    setCurrentValue(event.target.value);
  };
  const handleDelete = () => {
    setUrl('');
    setCurrentValue('');
    onImageDelete();
  };

  const handleDeletePreviewImage = () => {
    setDefaultPreviewDeleted(true);
    onImageDelete();
  };

  const showInput = customImageUpload ? Boolean((!url && !mediaUrl) || url) : !url;
  const showRemoveReference = customImageUpload && Boolean(url);
  const inputReadonly = customImageUpload && Boolean(url);

  return (
    <div className="bg-bgGray text-blue100 flex flex-col p-3" data-test-id="FormEmbedUpload">
      {url && (
        <EmbedPreview
          url={url}
          handleDeletePreviewImage={handleDeletePreviewImage}
          handleDelete={handleDelete}
          canEdit={canEdit}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          defaultPreviewDeleted={defaultPreviewDeleted}
          isMediaUpdating={isMediaUpdating}
          mediaUrl={mediaUrl}
          mediaLinkUrl={mediaLinkUrl}
          mediaPreviewUrl={mediaPreviewUrl}
          onImageDelete={onImageDelete}
          onMediaUpdate={onMediaUpdate}
          customImageUpload={customImageUpload}
        />
      )}
      {canEdit && (
        <div className="flex gap-2 wrap">
          {showInput && (
            <Input
              fluid
              inverted
              name="name"
              value={currentValue}
              onChange={handleChange}
              className="grow"
              placeholder={t('common.insertUrl')}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              data-testid="url"
              readOnly={inputReadonly}
            />
          )}

          {showRemoveReference && (
            <Button variant={ButtonType.Blue} className="flex flex-0" onClick={handleDelete}>
              {t('common.removeReference')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default FormEmbedUpload;
