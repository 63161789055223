import { call, put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import Paths from '../../../../constants/Paths.js';
import { push } from '../../../../lib/redux-router';
import * as selectors from '../../../../selectors';
import request from '../../request';

export function* duplicateCurrentProject(data) {
  const { projectId } = yield select(selectors.selectPath);
  let project;
  let projectManager;
  let boards;
  let boardMemberships;
  let projectProfessions;
  let lists;
  let cards;
  let attachments;
  try {
    ({
      project,
      projectManager,
      boards,
      boardMemberships,
      projectProfessions,
      lists,
      cards,
      attachments,
    } = yield call(request, api.duplicateProject, projectId, data));
  } catch (error) {
    yield put(actions.duplicateProject.failure(projectId, error));
    return;
  }
  yield put(
    actions.duplicateProject.success(
      project,
      projectManager,
      boards,
      boardMemberships,
      projectProfessions,
      lists,
      cards,
      attachments,
    ),
  );
  if (boards.length === 0) {
    return;
  }
  const firstBoard = boards[0];
  const firstBoardId = firstBoard.id;
  const firstBoardUrl = Paths.BOARDS.replace(':id', firstBoardId);
  yield put(push(firstBoardUrl));
}
