import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoardInfo from '../components/BoardInfo/BoardInfo';
import Paths from '../constants/Paths';
import * as entryActions from '../entry-actions/index';
import { push } from '../lib/redux-router';
import * as selectors from '../selectors';
import { getProjectThumbnailUrl } from '../utils/getProjectThumbnailUrl';

const mapStateToProps = (state) => {
  const project = selectors.selectCurrentProject(state);
  const board = selectors.selectCurrentBoard(state);
  const canEdit = selectors.selectCanEditBoard(state);
  const professions = selectors.selectProfessionsByProjectId(state, project.id);
  const thumbnailUrl = getProjectThumbnailUrl(project);
  const canDelete = selectors.selectCanDeleteBoard(state);
  return {
    project,
    board,
    editable: canEdit,
    image: thumbnailUrl,
    title: project.name,
    subtitle: project.subtitle || '',
    professions,
    canDelete,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      handleDelete: entryActions.scheduleCurrentProjectForDeletion,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  handleSettingsOpen() {
    dispatchProps.push(Paths.BOARD_SETTINGS.replace(':id', stateProps.board.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BoardInfo);
