import { omit } from 'lodash-es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardModal from '../components/CardModal';
import { cardColors } from '../constants/CardColors';
import Flags from '../constants/Flags.js';
import * as entryActions from '../entry-actions';
import { push } from '../lib/redux-router';
import * as selectors from '../selectors';
import { createCurrentDateWithoutSeconds } from '../utils/createCurrentDate';
import { getFinalMediaPreviewUrl } from '../utils/getFinalMediaPreviewUrl';

const mapStateToProps = (state) => {
  const { projectId } = selectors.selectPath(state);
  const {
    name,
    description,
    boardId,
    listId,
    embedUrl,
    ogTitle,
    ogDescription,
    ogImage,
    mediaUrl,
    backgroundColor,
    scheduledToBeVisibleAt,
    coverUrl,
    mediaPreviewUrl,
  } = selectors.selectCurrentCard(state);
  const attachments = selectors.selectAttachmentsForCurrentCard(state);
  const canEdit = selectors.selectCanEditBoard(state);
  const {
    ui: {
      cardModalForm: { error, isMediaUpdating },
    },
  } = state;

  const imageId = attachments?.[1]?.id || attachments?.[0]?.id || '';
  const previewImageId = attachments?.[0]?.id || attachments?.[1]?.id || '';

  const backgroundColors = cardColors.map((color) => color.value);
  const fallbackBackgroundColor = cardColors.find((color) => color.name === 'white').value;
  const actualBackgroundColor = backgroundColor || fallbackBackgroundColor;
  const showCalendar = Flags.CARD_SCHEDULING;

  const dateTimeDate = scheduledToBeVisibleAt
    ? new Date(scheduledToBeVisibleAt)
    : createCurrentDateWithoutSeconds();
  const dateTime = new Date(dateTimeDate.getTime() - dateTimeDate.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1);

  const showDuplicate = Flags.CARD_DUPLICATE;
  const descriptionFocusViaTab = Flags.CARD_DESCRIPTION_FOCUS_VIA_TAB;

  const customImageUpload = Flags.CUSTOM_IMAGE_UPLOAD;

  const finalMediaPreviewUrl = getFinalMediaPreviewUrl({
    customImageUpload,
    mediaPreviewUrl,
    coverUrl,
    mediaUrl,
  });

  return {
    name,
    description,
    listId,
    boardId,
    projectId,
    attachments,
    canEdit,
    error,
    embedUrl,
    mediaUrl,
    imageId,
    previewImageId,
    ogTitle,
    ogDescription,
    ogImage,
    isMediaUpdating,
    backgroundColor: actualBackgroundColor,
    backgroundColors,
    showCalendar,
    dateTime,
    showDuplicate,
    mediaPreviewUrl: finalMediaPreviewUrl,
    mediaLinkUrl: mediaUrl,
    descriptionFocusViaTab,
    customImageUpload,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: entryActions.updateCurrentCard,
      onAttachmentCreate: entryActions.createAttachmentInCurrentCard,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachment,
      onClose: entryActions.cardModalClose,
      push,
      openCardDuplicateModal: entryActions.openCardDuplicateModal,
      onDescriptionEditConfirmModalOpen: entryActions.openDescriptionEditConfirmModal,
      handlePreviewFileSelect: entryActions.createPreviewImageInCurrentCard,
      handlePreviewFileDelete: entryActions.deletePreviewImageInCurrentCard,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...omit(dispatchProps, 'push'),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CardModal);
