import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Login from '../components/Login';
import * as entryActions from '../entry-actions';
import * as selectors from '../selectors';

const mapStateToProps = (state) => {
  const oidcConfig = selectors.selectOidcConfig(state);

  const {
    ui: {
      authenticateForm: { data: defaultData, isSubmitting, isSubmittingUsingOidc, error },
    },
  } = state;

  return {
    defaultData,
    isSubmitting,
    isSubmittingUsingOidc,
    error,
    withOidc: !!oidcConfig,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAuthenticate: entryActions.authenticate,
      onAuthenticateUsingOidc: entryActions.authenticateUsingOidc,
      onRegisterUsingOidc: entryActions.registerUsingOidc,
      onMessageDismiss: entryActions.clearAuthenticateError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
