import { call, put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import core from '../../../../locales/de/core.js';
import * as selectors from '../../../../selectors';
import { getFormErrorMessage } from '../../../../utils/getFormErrorMessage.js';
import { getProjectDeletionDate } from '../../../../utils/getProjectDeletionDate';
import { simpleAlert } from '../../../../utils/simpleAlert.js';
import request from '../../request';
import { goToRoot } from '../router';

export function* scheduleProjectForDeletion(id: string) {
  const { projectId } = yield select(selectors.selectPath);
  yield put(actions.scheduleProjectForDeletion(id));
  const now = new Date();
  const deletionDate = getProjectDeletionDate(now);
  try {
    yield call(request, api.updateProject, id, {
      scheduledForDeletionAt: deletionDate.toUTCString(),
    });
  } catch (error) {
    // hack https://sprylab.atlassian.net/browse/MIKADO-454
    simpleAlert(
      getFormErrorMessage(error, (key) => core.translation.common[key.slice('common.'.length)]),
    );
    yield put(actions.scheduleProjectForDeletion.failure(id, error));
    return;
  }
  yield put(actions.scheduleProjectForDeletion.success(id));
  if (id === projectId) {
    yield call(goToRoot);
  }
}
