// @ts-nocheck

/* eslint-disable import/prefer-default-export */
import { createSelector } from 'redux-orm';
import { selectPath } from './router';
import orm from '../orm';

export const selectProfessions = createSelector(
  orm,
  (state) => selectPath(state),
  ({ Profession }) => {
    const professions = Profession.all().toModelArray();
    return professions;
  },
);
