import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardDuplicateModal from '../components/CardDuplicateModal/CardDuplicateModal';
import * as entryActions from '../entry-actions/index';
import * as selectors from '../selectors/index';

const mapStateToProps = (state) => {
  const {
    ui: {
      cardDuplicateForm: { isSubmitting, error },
    },
  } = state;
  const selectedProjectId = '';
  const card = selectors.selectCurrentCard(state);
  const currentProject = selectors.selectCurrentProject(state);
  const data = {
    targetProjectId: selectors.selectCurrentProject(state).id,
  };
  const projects = selectors.selectProjectsForCurrentUser(state);
  return {
    projects,
    selectedProjectId,
    isSubmitting,
    currentProject,
    error,
    defaultData: data,
    card,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClose: entryActions.closeModal,
      onDuplicateCard: entryActions.duplicateCard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardDuplicateModal);
