import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../components/Header';
import Flags from '../constants/Flags.js';
import * as entryActions from '../entry-actions';
import * as selectors from '../selectors';

const mapStateToProps = (state) => {
  const {
    ui: {
      headerForm: { searchExpanded },
    },
  } = state;
  const isLogouting = selectors.selectIsLogouting(state);
  const currentUser = selectors.selectCurrentUser(state);
  const currentBoard = selectors.selectCurrentBoard(state);
  const currentProject = selectors.selectCurrentProject(state);
  const pathMatch = selectors.selectPath(state);
  const { isMobile } = state.root;
  const canEdit = selectors.selectCanEditBoard(state);
  const canDelete = selectors.selectCanDeleteBoard(state);
  const showScrollChange = Flags.BOARD_FULL_SCROLL;
  const boardScrollFull = selectors.selectBoardScrollFull(state);
  const isPasswordProtected = Boolean(currentProject?.plainTextPassword);
  return {
    isLogouting,
    project: currentProject,
    board: currentBoard,
    isLoggedIn: !!currentUser,
    showBackButton: Boolean(currentProject) || pathMatch.showBackButton,
    showSearch: Flags.HEADER_SEARCH && !currentProject,
    isMobile: Flags.HEADER_MOBILE && isMobile,
    searchExpanded,
    user: currentUser,
    canEdit,
    showScrollChange,
    boardScrollFull,
    canDelete,
    isPasswordProtected,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAuthenticateUsingOidc: entryActions.authenticateUsingOidc,
      onLogout: entryActions.logout,
      openAbout: entryActions.openAboutModal,
      addBookmark: entryActions.addBookmark,
      removeBookmark: entryActions.removeBookmark,
      expandSearch: entryActions.expandSearch,
      collapseSearch: entryActions.collapseSearch,
      toggleScrollMode: entryActions.toggleScrollMode,
      handleDelete: entryActions.scheduleCurrentProjectForDeletion,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
