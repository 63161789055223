import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormEmbedUpload from '../FormEmbedUpload/FormEmbedUpload.js';
import MediaUpload from '../MediaUpload/MediaUpload.js';

const getShowOr = (
  canEdit: boolean,
  embedUrl: string,
  mediaUrl: string,
  defaultPreviewDeleted: boolean,
) => {
  if (!canEdit) {
    return false;
  }
  if (defaultPreviewDeleted) {
    return false;
  }
  if (embedUrl) {
    return false;
  }
  if (mediaUrl) {
    return false;
  }
  return true;
};

const getShowEmbed = (embedUrl: string, mediaUrl: string, customImageUpload: boolean) => {
  if (!customImageUpload && mediaUrl) {
    return false;
  }
  return true;
};

function FormEmbedOrMediaUpload({
  embedUrl,
  setUrl,
  mediaUrl,
  onMediaUpdate,
  handlePreviewFileSelect,
  handlePreviewFileDelete,
  onImageDelete,
  isMediaUpdating,
  canEdit,
  ogImage,
  ogTitle,
  ogDescription,
  mediaPreviewUrl,
  mediaLinkUrl,
  customImageUpload,
}: {
  embedUrl: string;
  setUrl: (url: string) => void;
  mediaUrl: string;
  onMediaUpdate: ({ file }: { file: File | null }) => void;
  handlePreviewFileSelect: ({ file }: { file: File | null }) => void;
  handlePreviewFileDelete: () => void;
  onImageDelete: () => void;
  isMediaUpdating: boolean;
  canEdit: boolean;
  ogImage: string;
  ogTitle: string;
  ogDescription: string;
  mediaPreviewUrl?: string;
  mediaLinkUrl?: string;
  customImageUpload: boolean;
}) {
  const [t] = useTranslation();
  const shouldShowMediaUpload = !embedUrl;
  const [defaultPreviewDeleted, setDefaultPreviewDeleted] = useState(false);
  const shouldShowEmbed = getShowEmbed(embedUrl, mediaUrl, customImageUpload);
  const shouldShowOr = getShowOr(canEdit, embedUrl, mediaUrl, defaultPreviewDeleted);

  return (
    <div
      className="bg-bgGray p-2.5 text-blue100 flex flex-col flex-gap-2 border border-px border-blue10 rounded-sm"
      data-test-id="FormEmbedOrMediaUpload"
    >
      {shouldShowMediaUpload && (
        <MediaUpload
          media={mediaUrl}
          handleFileSelect={onMediaUpdate}
          handlePreviewFileSelect={handlePreviewFileSelect}
          handleFileDelete={onImageDelete}
          isMediaUpdating={isMediaUpdating}
          canEdit={canEdit}
          mediaPreviewUrl={mediaPreviewUrl}
          mediaLinkUrl={mediaLinkUrl}
          customImageUpload={customImageUpload}
          handlePreviewFileDelete={handlePreviewFileDelete}
        />
      )}
      {shouldShowOr && (
        <div className="flex items-center justify-center">
          <span className="uppercase text-blue50 text-sm">{t('common.or')}</span>
        </div>
      )}
      {shouldShowEmbed && (
        <FormEmbedUpload
          url={embedUrl}
          setUrl={setUrl}
          canEdit={canEdit}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          setDefaultPreviewDeleted={setDefaultPreviewDeleted}
          defaultPreviewDeleted={defaultPreviewDeleted}
          mediaUrl={mediaUrl}
          onMediaUpdate={onMediaUpdate}
          onImageDelete={onImageDelete}
          isMediaUpdating={isMediaUpdating}
          mediaPreviewUrl={mediaPreviewUrl}
          mediaLinkUrl={mediaLinkUrl}
          customImageUpload={customImageUpload}
        />
      )}
    </div>
  );
}

export default React.memo(FormEmbedOrMediaUpload);
