import MediaPreviewEmbed from '../components/MediaPreviewEmbed/MediaPreviewEmbed';
import MediaPreviewFile from '../components/MediaPreviewFile/MediaPreviewFile';
import MediaPreviewImage from '../components/MediaPreviewImage/MediaPreviewImage';
import MediaPreviewLink from '../components/MediaPreviewLink/MediaPreviewLink';
import MediaPreviewNoop from '../components/MediaPreviewNoop/MediaPreviewNoop';
import MediaPreviewOpenGraph from '../components/MediaPreviewOpenGraph/MediaPreviewOpenGraph';
import MediaPreviewPdf from '../components/MediaPreviewPdf/MediaPreviewPdf';
import MediaPreviewVideo from '../components/MediaPreviewVideo/MediaPreviewVideo';
import MediaPreviewYoutube from '../components/MediaPreviewYoutube/MediaPreviewYoutube';
import MediaPreviewYoutubeNoCookie from '../components/MediaPreviewYoutubeNoCookie/MediaPreviewYoutubeNoCookie';
import MediaPreviewYoutubeShort from '../components/MediaPreviewYoutubeShort/MediaPreviewYoutubeShort';
import * as MediaPreviewType from '../constants/MediaPreviewType';

export const getMediaPreviewComponent = (mediaPreviewType: number) => {
  switch (mediaPreviewType) {
    case MediaPreviewType.Video:
      return MediaPreviewVideo;
    case MediaPreviewType.Image:
      return MediaPreviewImage;
    case MediaPreviewType.Link:
      return MediaPreviewLink;
    case MediaPreviewType.EmbedGoogleMaps:
    case MediaPreviewType.EmbedInstagram:
    case MediaPreviewType.EmbedPdf:
    case MediaPreviewType.EmbedSoundcloud:
    case MediaPreviewType.EmbedTwitter:
    case MediaPreviewType.EmbedVimeo:
      return MediaPreviewEmbed;
    case MediaPreviewType.EmbedYoutube:
      return MediaPreviewYoutube;
    case MediaPreviewType.Pdf:
      return MediaPreviewPdf;
    case MediaPreviewType.OpenGraph:
      return MediaPreviewOpenGraph;
    case MediaPreviewType.None:
      return MediaPreviewNoop;
    case MediaPreviewType.File:
      return MediaPreviewFile;
    case MediaPreviewType.EmbedYoutubeNoCookie:
      return MediaPreviewYoutubeNoCookie;
    case MediaPreviewType.EmbedYoutubeShort:
      return MediaPreviewYoutubeShort;
    default:
      throw new Error(`unsupported media preview type`);
  }
};
