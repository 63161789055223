import React, { useRef } from 'react';
import ReactEmbed from 'react-embed';
import { useMutationObserver } from 'rooks';

function Embed({
  url,
  heightOfIframe,
  makeEmbedFullHeight = false,
}: {
  url: string;
  heightOfIframe?: number;
  makeEmbedFullHeight?: boolean;
}) {
  const ref = useRef<HTMLDivElement>();

  useMutationObserver(ref, () => {
    const iframeWrapper = ref.current;
    if (!heightOfIframe || !iframeWrapper) {
      return;
    }
    const iframeElement = iframeWrapper.querySelector('iframe');
    const divWrapper = iframeWrapper.querySelector('div');

    // vimeo special
    const vimeo = iframeWrapper.querySelector('[data-vimeo-initialized="true"]');
    if (vimeo) {
      const parent = vimeo.parentElement;
      if (parent) {
        parent.style.height = 'auto';
      }
    }
    if (!iframeElement || !divWrapper) {
      return;
    }

    if (makeEmbedFullHeight) {
      iframeElement.style.height = '100%';
      divWrapper.style.height = '100%';
    }
  });

  return (
    <div ref={ref} className="flex justify-center lg:items-center h-full w-full contain-content">
      {url && <ReactEmbed url={url} />}
    </div>
  );
}

export default React.memo(Embed);
