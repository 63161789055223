import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Core from '../components/Core';
import * as entryActions from '../entry-actions/index';
import * as selectors from '../selectors';

const mapStateToProps = (state) => {
  const path = selectors.selectPath(state);
  const { isSlideshow } = path;
  const isInitializing = selectors.selectIsInitializing(state);
  const globalError = selectors.selectGlobalError(state);
  const isSocketDisconnected = selectors.selectIsSocketDisconnected(state);
  const documentTitle = selectors.selectDocumentTitle(state);
  const { modals } = selectors.selectCurrentModal(state);
  const { isMobile } = state.root;
  return {
    isInitializing,
    globalError,
    isSocketDisconnected,
    isSlideshow,
    documentTitle,
    isMobile,
    modals,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleResize: entryActions.handleResize,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Core);
