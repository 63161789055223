import { omit } from 'lodash-es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DescriptionEditConfirmModal from '../components/DescriptionEditConfirmModal/DescriptionEditConfirmModal';
import * as entryActions from '../entry-actions/index.js';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDiscardAndClose: entryActions.cardModalDiscardAndClose,
      onCancel: entryActions.closeModal,
      onClose: entryActions.closeModal,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...omit(dispatchProps, 'push'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DescriptionEditConfirmModal);
